body {
    margin: 0;
}

a {
    color: #1CD6CE;
}

a, a:visited, a:hover, a:active {
    color: #1CD6CE;
}